<template>
  <page-layout ref="layout" @refresh="refresh">
    <template #breadcrumbs="{ year }">
      <b-breadcrumb-item :text="`Events - ${year}`" />
      <b-breadcrumb-item text="Room Assignment" :to="{ name: 'all-state-acceptance' }" />
    </template>

    <template #actions="{ state }">
      <b-button :disabled="!save.changes.length" variant="link" size="sm" class="d-flex align-items-center" @click="saveChanges(state)">
        <b-icon-save class="mr-50" style="width: 14px; height: 14px;"/> Save
      </b-button>

      <b-button v-b-modal.import-modal variant="link" size="sm" class="d-flex align-items-center">
        <b-icon-upload class="mr-50" style="width: 14px; height: 14px;"/> Import
      </b-button>

      <b-button v-b-modal.export-modal variant="link" size="sm" class="d-flex align-items-center">
        <b-icon-download class="mr-50" style="width: 14px; height: 14px;"/> Export
      </b-button>
    </template>

    <template #dropdown-options>
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #loading="{ state }">
      <overlay-loading :items="[
          { state: ensembles.loading, desc: 'Loading Ensembles', loaded: ensembles.items.length},
          { state: table.loading, desc: 'Loading Applications', loaded: table.loaded},
          { state: state.loading, desc: 'Rendering Template'},
          ]">
      </overlay-loading>
    </template>
    
    <template #modal>
      <!-- Import Modal -->
      <import-modal
        :expected-fields="[
        'id',
        'student.id',
        'student.name.first',
        'student.name.last',
        'student.sex',
        'student.gender',
        'student.event.id',
        'student.event.busId',
        'student.event.chaperoneId',
        'student.event.hotelId',
        'student.event.room',
        ]"
        @import="setItemsFromImport"
      />

      <!-- Export Modal -->
      <!-- Export Modal Is Defined In TableLayout -->

      <!-- Processing Changes Modal -->
      <b-modal v-model="isSaving" size="xl" centered no-close-on-backdrop no-close-on-esc
               header-class="d-none" body-class="px-1 pt-1 pb-50" footer-class="d-none">
        <b-alert show variant="primary" class="d-print-none mb-0 p-1">
          <b-list-group>
            <b-list-group-item class="px-1 py-50">
              <div class="d-flex justify-content-between align-items-center p-50">
                Processed Changes ({{ save.computedChanges.length }})
                <div class="d-flex align-items-center">
                  <b-button size="sm" variant="link"
                            :aria-expanded="ui.processed.expanded ? 'true' : 'false'"
                            aria-controls="ui.processed.expanded"
                            class="mr-1"
                            @click="ui.processed.expanded = !ui.processed.expanded">
                    {{ ui.processed.expanded ? 'Hide' : 'Show' }} Details
                  </b-button>
                  <b-spinner v-if="save.processing" small :class="getSaveClass()"></b-spinner>
                  <b-icon v-else :icon="getSaveIcon(save)" :variant="getSaveVariant(save)" :class="getSaveClass()"/>
                </div>
              </div>
              <div v-if="save.processing" class="my-1">
                <small class="mb-25">{{ save.status }}</small>
                <b-progress>
                  <b-progress-bar :value="save.progress" :max="save.computedChanges.length"></b-progress-bar>
                </b-progress>
              </div>

              <hr class="my-50 opacity-50"/>
              <!-- Element to collapse -->
              <vue-perfect-scrollbar :settings="{ maxScrollbarLength: 150,wheelPropagation: false }" class="ps-processed">
                <b-collapse id="ui.processed.expanded" v-model="ui.processed.expanded">
                  <b-list-group v-if="save.computedChanges.length" flush class="p-50">
                    <b-list-group-item v-for="(item, index) in save.computedChanges" :key="index" class="px-0" :class="index === 0 ? 'pt-50 pb-1' : 'py-1'">
                      <div class="d-flex justify-content-between align-items-start">
                        <div>
                          <small class="text-capitalize font-weight-bold">{{ item.type }} Event</small>
                          <small class="d-block">Event: {{ item.event.id ? item.event.id : '---' }}</small>
                          <small v-for="(change, i2) in item.changes" :key="index + '-' + i2" class="d-block">
                            <span class="text-capitalize">{{ item.type }} {{ change.type }}</span> {{ fromToText(change.from, change.to) }}
                          </small>
                          <small class="d-block">
                            <span class="text-capitalize">{{ item.type }}d {{ item.updatedAt | date }}</span>
                          </small>
                          <small v-if="item.error" class="d-block text-danger">Error: {{ item.error }}</small>
                        </div>
                        <b-spinner v-if="item.saving" small></b-spinner>
                        <b-icon v-else :icon="item.icon" :variant="item.variant" class="mt-25"/>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                  <small v-else class="pt-50">Nothings been updated yet.</small>
                </b-collapse>
              </vue-perfect-scrollbar>
            </b-list-group-item>
          </b-list-group>
        </b-alert>
        <div class="d-flex justify-content-end">
          <b-button v-if="save.complete" block class="mt-50 shadow-none" variant="link" @click="highlightProcessed()">
            Back to Table
          </b-button>
        </div>
      </b-modal>
    </template>

    <template #content="{ state }">
      <template v-if="state.error || !isEnabled">
        <b-alert v-if="ui.error.show" show variant="danger" class="font-small-3">
          <b-icon-exclamation-circle class="mr-50"/> {{ ui.error.message }}
        </b-alert>
      </template>
      <template v-else>
        <table-layout
            ref="table-layout"
            :fields="table.fields"
            :search="table.search"
            :filters="table.filters" :filters-options="{ visible: true, collapsed: ui.filters.collapsed, cols: 20 }"
            :items="sortedItems"
            :loading="table.loading"
            :subscriptions="table.subscriptions"
            :func-refresh="refresh"
            :export-select-fields="[
                        'id',
                        'student.id',
                        'student.name.first',
                        'student.name.last',
                        'student.sex',
                        'student.gender',
                        'student.event.id',
                        'student.event.busId',
                        'student.event.chaperoneId',
                        'student.event.hotelId',
                        'student.event.room',
                    ]"
            :export-exclude-fields="[
                        'schoolID',
                        'school.id',
                        '_rowVariant',
                        'student.school.id',
                        'student.phone.*',
                        'application.id',
                        'application.instrument.order',
                        'createdAt',
                        'updatedAt',
                      ]">

          <template #overlay>
            <overlay-loading :items="[
                      { state: ensembles.loading, desc: 'Loading Ensembles', loaded: instruments.loaded},
                      { state: instruments.loading, desc: 'Loading Instruments', loaded: instruments.loaded},
                      { state: table.loading, desc: 'Loading Selections', loaded: table.loaded},
                    ]"/>
          </template>

          <template #filters>
            <b-form-group label="Ensemble" label-for="event-filter-ensemble">
              <v-select v-model="table.filters.ensemble.value"
                        input-id="event-filter-ensemble"
                        label="name"
                        :reduce="option => option.id"
                        :options="ensembles.items"
                        class="w-100 d-print-none"/>
            </b-form-group>
            <b-form-group label="Instrument" label-for="event-filter-instrument">
              <v-select v-model="table.filters.instrument.value"
                        input-id="event-filter-instrument"
                        label="name"
                        :reduce="option => option.id"
                        :options="instruments.items"
                        class="w-100 d-print-none"/>
            </b-form-group>
            <b-form-group label="Hotel" label-for="event-filter-hotel">
              <v-select v-model="table.filters.hotel.value"
                        input-id="event-filter-hotel"
                        label="name"
                        :reduce="option => option.id"
                        :options="hotels.items"
                        class="w-100 d-print-none">
                <template #option="{ name }">
                  <span :class="{'text-danger font-small-3': !name}">
                    {{ name ? name : 'Unnamed Hotel' }}
                  </span>
                </template>
                <template #selected-option="{ name }">
                  <span :class="{'text-danger': !name}">
                    {{ name ? name : 'Unnamed Hotel' }}
                  </span>
                </template>
              </v-select>
            </b-form-group>
            <b-form-group label="Chaperone" label-for="event-filter-chaperone">
              <v-select v-model="table.filters.chaperone.value"
                        input-id="event-filter-chaperone"
                        label="name"
                        :reduce="option => option.id"
                        :options="chaperones.items"
                        class="w-100 d-print-none">
                <template #option="{ name }">
                  <span :class="{'text-ui-danger font-small-3': !name.first || !name.last}">
                    {{ name.first || name.last ? getFullName({ name: name }) : 'Unnamed Chaperone' }}
                  </span>
                </template>
                <template #selected-option="{ name }">
                  <span :class="{'text-ui-danger': !name.first || !name.last}">
                    {{ name.first || name.last ? getFullName({ name: name }) : 'Unnamed Chaperone' }}
                  </span>
                </template>
              </v-select>
            </b-form-group>
            <b-form-group label="Sex" label-for="event-filter-sex">
              <v-select v-model="table.filters.sex.value"
                        input-id="event-filter-sex"
                        label="text"
                        :reduce="option => option.value"
                        :options="sexes"
                        class="w-100 d-print-none"/>
            </b-form-group>
            <b-form-group label="Gender" label-for="event-filter-gender">
              <v-select v-model="table.filters.gender.value"
                        input-id="event-filter-gender"
                        label="text"
                        :reduce="option => option.value"
                        :options="genders"
                        class="w-100 d-print-none"/>
            </b-form-group>
          </template>

          <template #sidebar>
            <template v-if="isEnabled && canEdit && $can('update', 'all-state-selection')">
              <!-- Pending Changes -->
              <b-alert show variant="primary" class="d-print-none p-50">
                <b-list-group class="p-50">
                  <b-list-group-item class="p-1">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="font-small-3">Pending Changes ({{ updatableValues.length }})</div>
                      <div class="d-flex align-items-center">
                        <b-button size="sm" variant="link"
                                  :aria-expanded="ui.changes.expanded ? 'true' : 'false'"
                                  aria-controls="ui.changes.expanded"
                                  class="px-0"
                                  @click="ui.changes.expanded = !ui.changes.expanded">
                          {{ ui.changes.expanded ? 'Hide' : 'Show' }} Details
                        </b-button>
                      </div>
                    </div>
                    <b-overlay :show="save.processing" variant="white" :opacity="1">
                      <b-collapse id="ui.changes.expanded" v-model="ui.changes.expanded">
                        <hr/>
                        <vue-perfect-scrollbar :settings="{ maxScrollbarLength: 150,wheelPropagation: false }" class="ps-changes">
                          <b-list-group v-if="updatableValues.length && !save.processing" flush class="px-0">
                            <b-list-group-item v-for="(item, index) in updatableValues" :key="index" class="px-50 pt-50 pb-1" :class="index !== 0 ? 'mt-50' : null">
                              <div class="d-flex justify-content-between align-items-start">
                                <div>
                                  <small class="text-capitalize font-weight-bold">{{ item.type }} Event</small>
                                  <small class="d-block">Event: {{ item.event.id }}</small>
                                  <small v-for="(change, i2) in item.changes" :key="index + '-' + i2" class="d-block">
                                    Change <span class="text-capitalize">{{ change.type }}</span> {{ fromToText(change.from, change.to) }}
                                  </small>
                                </div>
                                <b-button variant="link" size="sm" class="btn-icon" @click="filterOnSelectionId(item.id)">
                                  <b-icon-search />
                                </b-button>
                              </div>
                            </b-list-group-item>
                          </b-list-group>
                        </vue-perfect-scrollbar>

                        <b-list-group v-if="table.filters.selection.value && hasChanges(table.filters.selection.value)">
                          <b-list-group-item class="p-50 mt-1 rounded bg-light-primary" @click="table.filters.selection.value = null">
                            <div class="d-flex justify-content-between align-items-center">
                              <small class="text-capitalize font-weight-bold">Clear Student Filter</small>
                              <b-icon-funnel style="width: 14px; height: 14px" class="mx-50"/>
                            </div>
                          </b-list-group-item>
                        </b-list-group>

                        <b-list-group v-if="updatableValues.length && !save.processing">
                          <b-list-group-item class="p-50 mt-1 rounded bg-light-primary" @click="highlightChanges()">
                            <div class="d-flex justify-content-between align-items-center">
                              <small class="text-capitalize font-weight-bold">Highlight Changes</small>
                              <b-icon :icon="ui.changes.highlight ? 'check-square' : 'square'" style="width: 14px; height: 14px" class="mx-50 bg-white"/>
                            </div>
                          </b-list-group-item>
                        </b-list-group>
                        <small v-else class="pt-50">Nothings been updated yet.</small>
                      </b-collapse>
                    </b-overlay>
                  </b-list-group-item>
                </b-list-group>
              </b-alert>
              <!-- Issues -->
              <b-alert :show="potentialIssues.length > 0" variant="danger" class="d-print-none p-50">
                <b-list-group class="p-50">
                  <b-list-group-item class="p-1">
                    <div class="d-flex justify-content-between align-items-center">
                      Issues ({{ potentialIssues.length }})
                      <div class="d-flex align-items-center">
                        <b-button size="sm" variant="link"
                                  :aria-expanded="ui.potentialIssues.expanded ? 'true' : 'false'"
                                  aria-controls="ui.potentialIssues.expanded"
                                  class="px-0 text-danger"
                                  @click="ui.potentialIssues.expanded = !ui.potentialIssues.expanded">
                          {{ ui.potentialIssues.expanded ? 'Hide' : 'Show' }} Details
                        </b-button>
                      </div>
                    </div>
                    <b-overlay :show="save.processing" variant="white" :opacity="1">
                      <b-collapse id="ui.potentialIssues.expanded" v-model="ui.potentialIssues.expanded">
                        <hr/>
                        <vue-perfect-scrollbar :settings="{ maxScrollbarLength: 150,wheelPropagation: false }" class="ps-changes">
                          <b-list-group flush class="px-0">
                            <b-list-group-item v-for="(item, index) in potentialIssues" :key="index" class="px-50 pt-50 pb-1" :class="index !== 0 ? 'mt-50' : null">
                              <div class="d-flex justify-content-between align-items-start">
                                <div>
                                  <small class="text-capitalize font-weight-bold">Duplicate Student</small>
                                  <small class="d-block">{{ item.student.name.first }} {{ item.student.name.last }} appears {{ item.count }} times.</small>
                                </div>
                                <b-button variant="link" size="sm" class="btn-icon text-danger" @click="filterOnStudentId(item.student.id)">
                                  <b-icon-search />
                                </b-button>
                              </div>
                            </b-list-group-item>
                          </b-list-group>
                        </vue-perfect-scrollbar>

                        <b-list-group v-if="table.filters.student.value && isDuplicate(table.filters.student.value)">
                          <b-list-group-item class="p-50 mt-1 rounded bg-light-danger" @click="table.filters.student.value = null">
                            <div class="d-flex justify-content-between align-items-center">
                              <small class="text-capitalize font-weight-bold">Clear Student Filter</small>
                              <b-icon-funnel-fill style="width: 14px; height: 14px" class="mx-50"/>
                            </div>
                          </b-list-group-item>
                        </b-list-group>

                        <b-list-group v-if="potentialIssues.length">
                          <b-list-group-item class="p-50 mt-1 rounded bg-light-danger" @click="highlightIssues()">
                            <div class="d-flex justify-content-between align-items-center">
                              <small class="text-capitalize font-weight-bold">Highlight Issues</small>
                              <b-icon :icon="ui.potentialIssues.highlight ? 'check-square' : 'square'" style="width: 14px; height: 14px" class="mx-50 bg-white"/>
                            </div>
                          </b-list-group-item>
                        </b-list-group>
                      </b-collapse>
                    </b-overlay>
                  </b-list-group-item>
                </b-list-group>
              </b-alert>
            </template>
            <template v-if="state.debug">
              <debug title="save.changes" collapsed>
                {{ save.changes }}
              </debug>
              <debug title="updatableValues" collapsed>
                {{ updatableValues }}
              </debug>
              <debug title="potentialIssues" collapsed>
                {{ potentialIssues }}
              </debug>
            </template>
          </template>

          <template #row-details="{row}">
            <div class="mx-2 mb-1 p-50 rounded bg-light-primary font-small-3">
              <strong>Selection - </strong>
              <span class="mr-1">Created:  {{ row.item.createdAt | date }}</span> |
              <span class="ml-1">Updated: {{ row.item.updatedAt | date }}</span>
            </div>
          </template>

          <template #cell(student)="{data}">
            <b-media vertical-align="center" no-body class="d-print-none">
              <b-media-aside>
                <b-avatar button
                          variant="primary" size="3em"
                          :badge-variant="getSexBadgeVariant(data.item.student)"
                >
                  <font-awesome-icon :icon="getSexIcon(data.item.student)" size="2x" />
                  <template #badge>
                    {{ getSexBadge(data.item.student) }}
                  </template>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="align-self-center">
                <h6 class="mb-0">{{ data.item.student.name.first }} {{ data.item.student.name.last }}</h6>
                <template v-if="data.item.student && data.item.student.school && data.item.student.school.name">
                  <small class="student-school d-print-none">{{ data.item.student.school.name.legal }}</small>
                </template>
                <template v-else>
                  <small class="student-school d-print-none text-danger">No School</small>
                </template>
              </b-media-body>
            </b-media>
            <span class="d-none d-print-block">{{ data.item.student.name.first }} {{ data.item.student.name.last }}</span>
          </template>

          <template #cell(ensemble)="{data}">
            <div v-if="data.item.ensemble">
              {{ data.item.ensemble.name }}
            </div>
            <div v-else class="text-danger">
              No Ensemble
            </div>
          </template>

          <template #cell(application.instrument)="{data}">
            <div v-if="data.item.application.instrument">
              {{ data.item.application.instrument.name }}
            </div>
            <div v-else class="text-danger">
              No Instrument
            </div>
          </template>

          <template #cell(student.event.hotelId)="{data}">
            <b-input-group>
              <v-select v-model="data.item.student.event.hotelId"
                        :options="hotels.items" label="name"
                        :reduce="option => option.id"
                        :select-on-tab="true"
                        append-to-body :calculate-position="withPopper"
                        :disabled="isDuplicate(data.item.student.id)"
                        class="font-small-3 d-print-none table-select"
                        @input="onChange('hotelId', $event, rules.required, data.item)">
                <template #option="{ name }">
                  <span :class="{'text-danger font-small-3': !name}">
                    {{ name ? name : 'Unnamed Hotel' }}
                  </span>
                </template>
                <template #selected-option="{ name }">
                  <span :class="{'text-danger': !name}">
                    {{ name ? name : 'Unnamed Hotel' }}
                  </span>
                </template>
              </v-select>
            </b-input-group>
          </template>

          <template #cell(student.event.room)="{data}">
            <b-input-group>
              <b-input v-model="data.item.student.event.room"
                       :debounce="250"
                       :disabled="isDuplicate(data.item.student.id)"
                       class="d-print-none"
                       @update="onChange('room', $event, rules.room, data.item)"
              />
            </b-input-group>
            <span class="d-none d-print-block">{{ data.item.student.event.room }}</span>
          </template>

          <template #cell(student.event.chaperoneId)="{data}">
            <b-input-group>
              <v-select v-model="data.item.student.event.chaperoneId"
                        :options="chaperones.items" label="name"
                        :reduce="option => option.id"
                        :disabled="isDuplicate(data.item.student.id)"
                        :select-on-tab="true"
                        append-to-body :calculate-position="withPopper"
                        class="font-small-3 d-print-none table-select"
                        @input="onChange('chaperoneId', $event, rules.required, data.item)">
                <template #option="{ name }">
                  <span :class="{'text-ui-danger font-small-3': !name.first || !name.last}">
                    {{ name.first || name.last ? getFullName({ name: name }) : 'Unnamed Chaperone' }}
                  </span>
                </template>
                <template #selected-option="{ name }">
                  <span :class="{'text-ui-danger': !name.first || !name.last}">
                    {{ name.first || name.last ? getFullName({ name: name }) : 'Unnamed Chaperone' }}
                  </span>
                </template>
              </v-select>
            </b-input-group>
          </template>

          <!-- Column: Actions -->
          <template #cell(row-options)="{data}">
            <b-dropdown-item :to="{ name: 'events-student', params: { id: data.item.id } }"
                             :disabled="isDuplicate(data.item.student.id)"
                             class="table-row-option-view">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">View</span>
            </b-dropdown-item>
          </template>
        </table-layout>
      </template>
    </template>

    <template #debug>
      <b-row>
        <b-col>
          <debug title="Table">{{ table }}</debug>
        </b-col>
        <b-col>
          <debug title="Initial">{{ table.initialItems }}</debug>
        </b-col>
      </b-row>
    </template>
  </page-layout>
</template>

<script>
import vSelect from 'vue-select'
import PageLayout from '@/components/PageLayout.vue';
import {API, graphqlOperation} from 'aws-amplify';
import {
  listEnsembles,
  listInstruments, listSelections,
  getStudentEvent,
  onCreateStudentEvent,
  onDeleteStudentEvent,
  onUpdateStudentEvent,
} from './queries/housing';
import {
  createStudentEvent,
  updateStudentEvent,
  deleteStudentEvent,
  getChaperones,
  getHotels, StudentEvent,
} from '@/views/events/service';


import notify from '@/mixins/notify.mixin';
import OverlayLoading from '@/components/OverlayLoading.vue';
import Fuse from 'fuse.js';
import {validate} from 'vee-validate';
import Ripple from 'vue-ripple-directive';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import popper from '@/mixins/popper.mixin';
import TableLayout from '@/components/TableLayout.vue';
import LastModified from '@/components/LastModified.vue';
import {uuid} from 'vue-uuid';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';

import events from '@/mixins/event.mixin';
import {genders, sexes} from '@/views/events/service/data';
import ImportModal from '@/components/ImportModal.vue';
import store from '@/store';


export default {
  directives: {
    Ripple,
  },
  filters: {
    date(value) {
      return value ? new Intl.DateTimeFormat('en', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      }).format(new Date(value)) : null
    }
  },
  components: {
    ImportModal,
    BCardActions,
    LastModified,
    TableLayout,
    OverlayLoading,
    PageLayout,
    vSelect,
    VuePerfectScrollbar
  },
  mixins: [events, notify, popper],
  data() {
    return {
      instruments: {
        loading: true,
        items: []
      },
      ensembles: {
        loading: true,
        items: []
      },
      hotels: {
        loading: true,
        items: [ ],
      },
      chaperones: {
        loading: true,
        items: [ ],
      },
      sexes: sexes,
      genders: genders,
      table: {
        loading: false,
        fields: [
          {
            key: 'student',
            subKeys: ['student.id', 'student.name.first', 'student.name.last'],
            label: 'Student',
            visible: true,
            sortable: true,
            filterable: true,
            tdClass: ['align-middle', 'font-small-3']
          },
          {
            key: 'student.school.name.legal',
            label: 'School',
            visible: false,
            sortable: true,
            filterable: true,
            tdClass: ['align-middle', 'font-small-3']
          },
          {
            key: 'student.school.zone.name',
            label: 'Zone',
            visible: false,
            sortable: true,
            tdClass: ['align-middle', 'font-small-3']
          },
          {
            key: 'ensemble',
            label: 'Ensemble',
            visible: true,
            sortable: true,
            tdClass: ['align-middle', 'font-small-3']
          },
          {
            key: 'application.instrument',
            label: 'Instrument',
            visible: true,
            sortable: true,
            tdClass: ['align-middle', 'font-small-3']
          },
          {
            key: 'student.sex',
            label: 'Sex',
            visible: true,
            sortable: true,
            tdClass: ['align-middle', 'font-small-3']
          },
          {
            key: 'student.gender',
            label: 'Gender',
            visible: true,
            sortable: true,
            filterable: true,
            tdClass: ['align-middle', 'font-small-3']
          },
          {
            key: 'student.phone.number',
            label: 'Phone',
            visible: false,
            tdClass: ['align-middle', 'font-small-3']
          },
          {
            key: 'student.event.hotelId',
            subKeys: ['student.hotel.name'],
            label: 'Hotel',
            visible: true,
            sortable: true,
            tdClass: ['align-middle', 'col-hotel']
          },
          {
            key: 'student.event.room',
            label: 'Room',
            visible: true,
            sortable: true,
            tdClass: ['align-middle', 'col-room']
          },
          {
            key: 'student.event.chaperoneId',
            subKeys: ['student.chaperone.name.first', 'student.chaperone.name.last'],
            label: 'Chaperone',
            visible: true,
            sortable: true,
            tdClass: ['align-middle', 'col-chaperone']
          },
          {
            key: 'row-options',
            label: '',
            sortable: false,
            filterable: false,
            visible: true,
            tdClass: ['align-middle', 'table-row-options']
          },
        ],
        filters: {
          selection: { key: 'id', value: null },
          ensemble: { key: 'ensemble.id', value: null },
          instrument: { key: 'application.instrument.id', value: null },
          hotel: { key: 'student.event.hotelId', value: null },
          chaperone: { key: 'student.event.chaperoneId', value: null },
          sex: { key: 'student.sex', value: null },
          gender: { key: 'student.gender', value: null },
          student: { key: 'student.id', value: null },
        },
        items: [],
        initialItems: [],
        search: null,
        subscription: {
          onCreate: null,
          onUpdate: null,
          onDelete: null
        },
      },
      save: {
        complete: false,
        processing: false,
        status: '',
        changes: [],
        computedChanges: [],
        errors: [],
        progress: 0,
        processed: []
      },
      ui: {
        layout: {
          showTooltips: true,
          showLeftColumn: true,
        },
        filters: {
          collapsed: false,
        },
        changes: {
          expanded: false,
          highlight: false
        },
        processed: {
          expanded: true
        },
        potentialIssues: {
          expanded: false,
          highlight: false
        },
        issues: {
          expanded: false
        },
        error: {
          show: false,
          message: ''
        }
      },
      rules: {
        room: {
          required: false,
        },
        required: {required: false},
      },
      icon: 'fas fa-check',
    }
  },
  computed: {
    isEnabled() {
      return true
    },
    canEdit() {
      return true
    },
    sortedItems() {
      const fuse = new Fuse(this.table.items, {
        useExtendedSearch: true,
        threshold: 0.2,
        keys: [
          'id',
          'ensemble.id',
          'application.instrument.id',
          'student.id',
          'student.sex',
          'student.gender',
          'student.name.full',
          'student.event.hotelId',
        ]
      })
      const query = {$and: []}
      if (this.table.filters.instrument.value) {
        query.$and.push({'application.instrument.id': `'${this.table.filters.instrument.value}`})
      }
      if (this.table.filters.ensemble.value) {
        query.$and.push({'ensemble.id': `'${this.table.filters.ensemble.value}`})
      }
      if (this.table.filters.hotel.value) {
        query.$and.push({'student.event.hotelId': `'${this.table.filters.hotel.value}`})
      }

      if (this.table.search) {
        query.$and.push({
          $or: [
            {id: `'${this.table.search}`},
            {'student.id': `'${this.table.search}`},
            {'student.name.full': `'${this.table.search}`}
          ]
        })
      }

      if (query.$and.length) {
        return fuse.search(query).map(({item}) => item)
      }
      return this.table.items
    },
    updatableValues() {
      const values = []
      this.table.initialItems.forEach(item => {
        const changes = this.save.changes.filter(change => change.id === item.id)
        if(changes.length) {
          const input = {
            id: item.id,
            event: {
              id: item?.student?.event?.id,
              studentId: item?.student?.id,
              chaperoneId: null,
              hotelId: null,
              room: null
            },
            type: null,
            changes: [],
          }

          const chaperoneId = this.save.changes.find(change => change.id === item.id && change.key === 'chaperoneId')
          if(chaperoneId) {
            input.event.chaperoneId = chaperoneId.value
            input.changes.push({ type: 'chaperoneId', from: this.getChaperoneName(item.student.event.chaperoneId), to: this.getChaperoneName(chaperoneId.value)})
          }
          else { input.event.chaperoneId = item.student.event.chaperoneId }

          const hotelId = this.save.changes.find(change => change.id === item.id && change.key === 'hotelId')
          if(hotelId) {
            input.event.hotelId = hotelId.value
            input.changes.push({ type: 'hotelId', from: this.getHotelName(item.student.event.hotelId), to: this.getHotelName(hotelId.value)})
          }
          else { input.event.hotelId = item.student.event.hotelId }

          const room = this.save.changes.find(change => change.id === item.id && change.key === 'room')
          if(room) {
            input.event.room = room.value
            input.changes.push({ type: 'room', from: item.student.event.room, to: room.value })
          }
          else { input.event.room = item.student.event.room }

          /** Determine Date **/
          input.date = new Date(Math.max(...changes.map(change => new Date(change.date)))).toISOString();

          /** Determine Type **/
          if(!input.event?.id) { input.type = 'create' }
          else if(input?.event?.id
              && input.event.hotelId === null
              && input.event.room === null
              && input.event.chaperoneId === null) { input.type = 'delete' }
          else { input.type = 'update' }

          values.push(input)
        }
      })
      return values.sort((a, b) => b.date.localeCompare(a.date))
    },
    potentialIssues() {
      const counter = {}
      this.table.items.forEach(obj => {
        const key = obj.student.id
        counter[key] = (counter[key] || 0) + 1
      })

      const output = []
      Object.entries(counter)
          .forEach(entry => {
            const [key, value] = entry;
            if (value > 1) {
              output.push({
                student: this.table.items.find(item => item.student.id === key).student,
                count: value
              })
            }
          });
      return output
    },
    duplicateStudents() {
      // Count the occurrence of each student ID.
      const counts = this.table.items.reduce((acc, item) => {
        acc[item.student.id] = (acc[item.student.id] || 0) + 1;
        return acc;
      }, {});

      // Filter out the student IDs that only occur once to find duplicates.
      const duplicates = this.table.items
          .filter(item => counts[item.student.id] > 1)
          .reduce((acc, item) => {
            // If the student is not already in the accumulator, add them.
            if (!acc[item.student.id]) {
              acc[item.student.id] = {
                ...item,
                count: counts[item.student.id], // The count of duplicates
              };
            }
            return acc;
          }, {});
      return Object.values(duplicates);
    },
    isSaving() {
      return this.save.processing || this.save.complete
    },
  },
  async mounted() {
    await this.listInstruments()
    await this.listEnsembles()
    await this.listHotels()
    await this.listChaperones()
    await this.listSelections()
    await this.onCreateStudentEvent()
    await this.onUpdateStudentEvent()
    await this.onDeleteStudentEvent()
    await this.$nextTick(() => {
      setTimeout(() => {
        this.$refs.layout.state.loading = false
      }, 500);
    })
  },
  beforeDestroy() {
    if (this.table?.subscriptions?.onCreate) {
      this.table.subscriptions.onCreate.unsubscribe()
    }
    if (this.table?.subscriptions?.onUpdate) {
      this.table.subscriptions.onUpdate.unsubscribe()
    }
    if (this.table?.subscriptions?.onDelete) {
      this.table.subscriptions.onDelete.unsubscribe()
    }
  },
  methods: {
    async refresh() {
      await this.listInstruments()
      await this.listEnsembles()
      await this.listSelections()
    },

    async listEnsembles() {
      const response = await API.graphql(graphqlOperation(listEnsembles));
      const items = response.data.listEnsembles.items.sort((a, b) => a.name.localeCompare(b.name));
      this.ensembles.items = items
      this.ensembles.loading = false
    },
    async listInstruments() {
      const response = await API.graphql(graphqlOperation(listInstruments));
      this.instruments.items = response.data.listInstruments.items.sort((a, b) => a.name.localeCompare(b.name));
      this.instruments.loading = false
    },
    async listHotels() {
      this.hotels.loading = true;
      await getHotels().then(hotels => {
        this.hotels.items = hotels;
        this.hotels.loading = false;
        this.hotels.loaded = true;
      })
    },
    async listChaperones() {
      this.chaperones.loading = true;
      await getChaperones({ includeHotel: true }).then(chaperones => {
        this.chaperones.items = chaperones;
        this.chaperones.loading = false;
        this.chaperones.loaded = true;
      })
    },
    async listSelections(nextToken, pagedItems) {
      this.table.loading = true
      const current = store.getters['settings/getCurrent'];
      const items = pagedItems || []
      const input = {
        filter: {
          accepted: { eq: true },
          createdAt: { between: [current.year.start, current.year.end] }
        },
        limit: 500,
        nextToken: nextToken
      }

      const response = await API.graphql(graphqlOperation(listSelections, input));
      items.push(...response.data.listSelections.items)

      if (response.data.listSelections.nextToken) {
        await this.listSelections(response.data.listSelections.nextToken, items)
      }
      else {
        this.table.items = this.mapSelections(items) //create table items
        this.table.items.forEach(item => {
          item._rowVariant = null
          if(this.ui.potentialIssues.highlight && this.isDuplicate(item.student.id)) {
            item._rowVariant = 'light-danger'
          }
          if(this.ui.changes.highlight && this.hasChanges(item.id)) {
            item._rowVariant = 'light-primary'
          }
        })

        this.table.initialItems = JSON.parse(JSON.stringify(this.table.items.map(item => ({ id: item.id, student: item.student }) )))
        this.table.loading = false
      }
    },
    async onCreateStudentEvent() {
      this.table.subscription.onCreate = API.graphql(graphqlOperation(onCreateStudentEvent)).subscribe(async (sourceData) => {
        const createdStudentEvent = sourceData.value.data.onCreateStudentEvent
        if(createdStudentEvent) {
          const response = await API.graphql(graphqlOperation(getStudentEvent, { id: createdStudentEvent.id }));
          const studentEvent = response.data.getStudentEvent
          if(studentEvent) {
            //this.syncNotification()

            const selection = this.table.items.find(item => item.student.id === studentEvent.studentId)
            if(selection) {
              selection.student.event = studentEvent
            }

            const initialItem = this.table.initialItems.find(item => item.student.id === studentEvent.studentId)
            if(initialItem) {
              initialItem.event = JSON.parse(JSON.stringify(studentEvent))
            }
          }
        }
      })
    },
    async onUpdateStudentEvent() {
      this.table.subscription.onUpdate = API.graphql(graphqlOperation(onUpdateStudentEvent)).subscribe(async (sourceData) => {
        const updatedStudentEvent = sourceData.value.data.onUpdateStudentEvent
        if(updatedStudentEvent) {
          const response = await API.graphql(graphqlOperation(getStudentEvent, { id: updatedStudentEvent.id }));
          const studentEvent = response.data.getStudentEvent
          if(studentEvent) {
            //this.syncNotification()
            const selection = this.table.items.find(item => item.student.event.id === studentEvent.id)
            if(selection) {
              selection.student.event = studentEvent
            }

            const initialItem = this.table.initialItems.find(item => item.student.event.id === studentEvent.id)
            if(initialItem) {
              initialItem.event = JSON.parse(JSON.stringify(studentEvent))
            }
          }
        }
      })
    },
    async onDeleteStudentEvent() {
      this.table.subscription.onDelete = API.graphql(graphqlOperation(onDeleteStudentEvent)).subscribe(async (sourceData) => {
        const deletedSelection = sourceData.value.data.onDeleteStudentEvent
        if(deletedSelection) {
          //this.syncNotification()

          const selection = this.table.items.find(item => item.student.event.id === deletedSelection.id)
          if(selection) {
            selection.student.event = new StudentEvent({ id: null, studentId: selection.student.id })
          }

          const initialItem = this.table.initialItems.find(item => item.student.event.id === deletedSelection.id)
          if(initialItem) {
            initialItem.event = new StudentEvent({ id: null, studentId: selection.student.id })
          }
        }
      })
    },

    /** Mapping **/
    mapSelections(items) {
      const sortedItems = items.sort((a, b) =>
          a?.application?.instrument?.order - b?.application?.instrument?.order
          || a.part - b.part
          || a?.application?.student?.name?.last.localeCompare(b?.application?.student?.name?.last)
          || a?.application?.student?.name?.first.localeCompare(b?.application?.student?.name?.first))

      return sortedItems.map((item) => this.mapSelection(item));
    },
    mapSelection(item) {
      if (!item) return null
      return {
        id: item.id,
        ensemble: item?.ensemble,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,

        student: {
          id: item?.application?.student?.id,
          name: {
            first: item.application?.student?.name?.first,
            last: item.application?.student?.name?.last,
            full: `${item.application?.student?.name?.first} ${item.application?.student?.name?.last}`
          },
          phone: {
            number: item.application?.student?.phone?.number,
          },
          sex: item.application?.student?.sex,
          gender: item.application?.student?.gender,
          school: item.application?.student?.school,
          event: {
            id: item.application?.student?.event?.id ?? null,
            busId: item.application?.student?.event?.busId ?? null,
            hotelId: item.application?.student?.event?.hotelId ?? null,
            chaperoneId: item.application?.student?.event?.chaperoneId ?? null,
            room: item.application?.student?.event?.room ?? null
          }
        },

        application: {
          id: item?.application?.id,
          instrument: item?.application?.instrument,
        },
        _rowVariant: null
      }
    },


    /** Import **/
    setItemsFromImport(items) {
      const map = new Map();
      for (const item of items) {
        map.set(item.id, item);
      }
      this.table.items.filter(item => !this.isDuplicate(item.student.id)).forEach(item => {
        this.deepMerge(item.id, item, map.get(item.id) ?? item);
      })
      return new Promise((resolve) => {
        setTimeout(() => {
          this.highlightChanges(true)
          resolve()
        }, 1000)
      })
    },

    deepMerge(id, target, source) {
      // Iterate over each property in the target object
      Object.keys(target).forEach(key => {
        // Only proceed if the source also has this property
        // eslint-disable-next-line no-prototype-builtins
        if (source.hasOwnProperty(key)) {
          const sourceValue = source[key];
          const targetValue = target[key];

          // Check if both the target and source values are objects (and not arrays)
          if (sourceValue && typeof sourceValue === 'object' && !Array.isArray(sourceValue)
              && targetValue && typeof targetValue === 'object' && !Array.isArray(targetValue)) {
            // Recursively merge objects
            this.deepMerge(id, targetValue, sourceValue);
          }
          else {
            switch(key) {
              case 'chaperoneId': {
                this.mergeChange(id, key, sourceValue, target, this.rules.required)
                break;
              }
              case 'hotelId': {
                this.mergeChange(id, key, sourceValue, target, this.rules.required)
                break;
              }
              case 'room': {
                this.mergeChange(id, key, sourceValue, target, this.rules.room)
                break;
              }
              default: break;
            }
          }
        }
      });
      return target;
    },
    mergeChange(selectionId, key, value, target, rules) {
      /* eslint-disable no-param-reassign */
      if(value === '') { value = null }
      if(target[key] === value) { return }

      validate(value, rules).then(result => {
        if (result.valid) {
          target[key] = value

          const initialItem = this.table.initialItems.find(item => item.id === selectionId)
          if(initialItem?.student?.event?.[key] === value) {
            this.save.changes = this.save.changes.filter(change => !(change.id === selectionId && change.key === key))
          }
          else {
            const changeValue = { id: selectionId, key: key, value: value, date: new Date().toISOString() }
            const index = this.save.changes.findIndex(change => change.id === selectionId && change.key === key)
            if(index >= 0) {
              this.save.changes.splice(index, 1, changeValue)
            }
            else {
              this.save.changes.push(changeValue)
            }
          }
        }
        else {
          this.save.changes = this.save.changes.filter(change => !(change.id === selectionId && change.key === key))
        }
      })
    },

    /** Save **/
    async saveChanges() {
      this.table.loading = true
      this.save.complete = false

      if (this.isEnabled) {
        this.save.processing = true
        this.save.status = 'Computing Changes'
        this.save.computedChanges = this.getComputedChanges()

        this.save.status = 'Saving Changes'
        await this.save.computedChanges.reduce(async (referencePoint, change, index) => {
          try {
            change.saving = true
            await referencePoint;
            this.save.progress = index + 1

            if(change.type === 'create') {
              const id = uuid.v4()
              await createStudentEvent({
                id: id,
                studentId: change.event.studentId,
                chaperoneId: change.event.chaperoneId,
                hotelId: change.event.hotelId,
                room: change.event.room,
              })
              change.event.id = id
            }
            else if(change.type === 'update') {
              await updateStudentEvent({
                id: change.event.id,
                studentId: change.event.studentId,
                chaperoneId: change.event.chaperoneId,
                hotelId: change.event.hotelId,
                room: change.event.room,
              })
            }
            else if(change.type === 'delete') {
              await deleteStudentEvent({
                id: change.event.id,
                studentId: change.event.studentId,
              })
            }

            change.success = true
            change.variant = 'success'
            change.icon = 'check-circle-fill'
            change.updatedAt = new Date().toISOString()
          }
          catch (e121) {
            console.error(e121)
            change.success = false
            change.variant = 'danger'
            change.icon = 'x-circle-fill'
            change.error = e121
            this.save.errors.push({
              event: change.id,
              error: e121
            })
          } finally {
            change.saving = false
          }
        }, Promise.resolve());


        // Update Initial Items, as well as revert back bad values in the table to their initial value
        this.save.status = 'Updating Initial Items'
        this.save.computedChanges.forEach(change => {
          const initialItem = this.table.initialItems.find(item => item.id === change.id)
          if (initialItem) {
            initialItem.student.event = change.event
          }

          const tableItem = this.table.items.find(item => item.id === change.id)
          if (tableItem) {
            tableItem.updatedAt = change?.updatedAt ? change?.updatedAt : tableItem.updatedAt
          }
        })

        this.save.processing = false
        this.save.changes = []
        this.save.complete = true
        this.table.loading = false
      } else {
        this.ui.error.show = true
        this.ui.error.message = 'Acceptance has been disabled, all changes made were not be saved.'
      }
    },
    getComputedChanges() {
      const values = []
      this.table.initialItems.forEach(item => {
        const changes = this.save.changes.filter(change => change.id === item.id)
        if(changes.length) {
          const input = {
            id: item.id,
            event: {
              id: item?.student?.event?.id,
              studentId: item?.student?.id,
              chaperoneId: null,
              hotelId: null,
              room: null,
            },
            type: null,
            changes: [],
            saving: false,
            success: null,
            error: null,
            icon: 'circle',
            variant: 'primary'
          }


          const chaperoneId = this.save.changes.find(change => change.id === item.id && change.key === 'chaperoneId')
          if(chaperoneId) {
            input.event.chaperoneId = chaperoneId.value
            input.changes.push({ type: 'chaperoneId', from: this.getChaperoneName(item.student.event.chaperoneId), to: this.getChaperoneName(chaperoneId.value)})
          }
          else { input.event.chaperoneId = item.student.event.chaperoneId }

          const hotelId = this.save.changes.find(change => change.id === item.id && change.key === 'hotelId')
          if(hotelId) {
            input.event.hotelId = hotelId.value
            input.changes.push({ type: 'hotelId', from: this.getHotelName(item.student.event.hotelId), to: this.getHotelName(hotelId.value)})
          }
          else { input.event.hotelId = item.student.event.hotelId }

          const room = this.save.changes.find(change => change.id === item.id && change.key === 'room')
          if(room) {
            input.event.room = room.value
            input.changes.push({ type: 'room', from: item.student.event.room, to: room.value })
          }
          else { input.event.room = item.student.event.room }

          /** Determine Type **/
          if(!input.event?.id) { input.type = 'create' }
          else if(
            input?.event?.id
            && input.event.chaperoneId === null
            && input.event.hotelId === null
            && input.event.room === null
          ) { input.type = 'delete' }
          else { input.type = 'update' }

          values.push(input)
        }
      })
      return values
    },

    /** Input **/
    onChange(key, value, rules, selection) {
      if(value === '') {
        // eslint-disable-next-line no-param-reassign
        value = null
        selection.student.event[key] = null
      }

      validate(value, rules).then(result => {
        if (result.valid) {
          const initialItem = this.table.initialItems.find(item => item.id === selection.id)
          if(initialItem?.student?.event?.[key] === value) {
            this.save.changes = this.save.changes.filter(change => !(change.id === selection.id && change.key === key))
          }
          else {
            const changeValue = { id: selection.id, key: key, value: value, date: new Date().toISOString() }
            const index = this.save.changes.findIndex(change => change.id === selection.id && change.key === key)
            if(index >= 0) {
              this.save.changes.splice(index, 1, changeValue)
            }
            else {
              this.save.changes.push(changeValue)
            }
          }
        }
        else {
          this.save.changes = this.save.changes.filter(change => !(change.id === selection.id && change.key === key))
        }
      })
    },


    onBlur(key, item) {
      const initialItem = this.table.initialItems.find(ii => ii.id === item.id)
      if (initialItem && initialItem?.student.event[key] === item?.student.event[key]) {
        this.$refs[`${item.id}-${key}-provider`].reset()
      }
    },
    undoToInitialItem(key, item) {
      const initialItem = this.table.initialItems.find(initial => initial.id === item.id)
      if (initialItem) {
        item.student.event[key] = initialItem.student.event[key]
        this.save.changes = this.save.changes.filter(change => !(change.id === item.id && change.key === key))
        this.$refs[`${item.id}-${key}-provider`].reset()
      }
    },


    /** Util **/
    highlightChanges(isHighlighted = null) {
      this.ui.changes.highlight = (isHighlighted !== null) ? isHighlighted : !this.ui.changes.highlight
      this.table.items.filter(item => this.hasChanges(item.id)).forEach(item => {
        item._rowVariant = this.ui.changes.highlight ? 'light-primary' : null
      });
    },
    highlightIssues(isHighlighted = null) {
      this.ui.potentialIssues.highlight = (isHighlighted !== null) ? isHighlighted : !this.ui.potentialIssues.highlight
      this.table.items.filter(item => this.isDuplicate(item.student.id)).forEach(item => {
        item._rowVariant = this.ui.potentialIssues.highlight ? 'light-danger' : null
      });
    },

    highlightProcessed(isHighlighted = null) {
      this.save.computedChanges.filter(change => change.success).forEach(change => {
        const item = this.table.items.find(i => i.id === change.id)
        if(item) {
          item._rowVariant = isHighlighted === true ? change.variant : null
        }
      })
      this.save.complete = false
    },



    fromToText(from, to) {
      return `from ${from !== null ? from : 'null'} to ${to !== null ? to : 'null'}`
    },
    getChaperoneName(id) {
      const chaperone = this.chaperones.items.find(item => item.id === id)
      if(!chaperone) return null
      return `${chaperone.name.first} ${chaperone.name.last}`
    },
    getHotelName(id) {
      return this.hotels.items.find(item => item.id === id)?.name ?? null
    },

    getSaveIcon(save) {
      if (save.complete) {
        if (save.computedChanges.every(item => item.success)) return 'check-circle-fill'
        if (save.computedChanges.every(item => item.error)) return 'x-circle-fill'
        return 'slash-circle-fill'
      }
      return 'circle'
    },
    getSaveVariant(save) {
      if (save.complete) {
        if (save.computedChanges.every(item => item.success)) return 'success'
        if (save.computedChanges.every(item => item.error)) return 'danger'
        return 'warning'
      }
      return 'primary'
    },
    getSaveClass() {
      if (this.save.processing || this.save.complete) {
        if (this.$refs['processed-scrollbar']?.ps?.scrollbarYActive === true) {
          return 'mr-2'
        }
      }
      return ''
    },

    filterOnSelectionId(id) {
      this.ui.filters.collapsed = true
      this.table.filters.student.value = null
      this.table.filters.selection.value = id
    },
    filterOnStudentId(id) {
      this.ui.filters.collapsed = true
      this.table.filters.selection.value = null
      this.table.filters.student.value = id
    },

    /** Validation **/
    getValidationState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null;
    },
    getValidationClass(validationContext) {
      const state = this.getValidationState(validationContext)
      if (state === null) return null
      return state ? 'is-valid' : 'is-invalid'
    },
    canUpdate(ref) {
      return new Promise((resolve, reject) => {
        this.$refs[ref].validate()
            .then(success => {
              if (success) {
                resolve(true)
              } else {
                reject()
              }
            })
      })
    },
    isDuplicate(studentId) {
      return this.duplicateStudents.some(s => s.student.id === studentId)
    },
    hasChanges(id) {
      return this.save.changes.some(change => change.id === id)
    },
  }
}
</script>

<style lang="scss">
.td-details-row {
  border-top: 0 !important;
  padding: 0 !important;
}

.table.b-table > tbody > tr.b-table-details > td {
  border-top: none !important;
}

.b-table-details > td > table > tbody > tr > td {
  border-top: 0 !important;
}

.tr-group-2 {
  background-color: #f5f5f5 !important;
}

.rounded-bottom-only {
  border-radius: 0 0 0.357rem 0.357rem;
}

.alert .list-group .list-group-item:hover {
  background-color: #fff;
}

.ps-changes {
  max-height: 300px;
  .ps__rail-y {
    right: -10px!important;
  }
}

.ps--active-y.ps-changes {
  //padding-right: 1.5rem
}

.ps-processed {
  max-height: 50vh;
}

.ps--active-y.ps-processed {
  padding-right: 1.5rem
}

</style>

<style lang="scss" scoped>
::v-deep {

  .acceptance-group > * {
    color: #b9b9c3 !important;
  }

  .acceptance-group .undecided.active {
    color: #F1C243 !important;
  }

  .acceptance-group .declined.active {
    color: #e42728 !important;
  }

  .acceptance-group .accepted.active {
    color: #28c76f !important;
  }

  .col-student {
    width: 30%;
    vertical-align: middle !important;
  }

  .col-ensemble {
    width: 20%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    vertical-align: middle !important;
  }

  .col-instrument {
    width: 20%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    vertical-align: middle !important;
  }

  .col-room {
    min-width: 50px;
    max-width: 100px;
    vertical-align: middle !important;
  }

  .col-hotel {
    min-width: 150px;
    max-width: 200px;
    vertical-align: middle !important;
  }

  .col-chaperone {
    min-width: 150px;
    max-width: 200px;
    vertical-align: middle !important;
  }

  .col-accepted {
    width: 20%;
    vertical-align: middle !important;
  }



  @media (max-width: 1530px) {
    /*.media-aside {
      margin-right:0!important;
    }
    .b-avatar {
      display: none!important;
    }*/
    /*.col-student {
      width: 24%!important;
    }
    .col-festival {
      width: 10%!important;
    }
    .col-score {
      width: 10%!important;
    }
    .col-local {
      width: 10%!important;
    }*/
    .col-state {
      width: 12% !important;
    }
    .col-ensemble {
      width: 22% !important;
    }
    .col-part {
      width: 12% !important;
    }
    .student-school {
      display: none !important;
    }
    .festival-prefix {
      display: none !important;
    }
  }


  @media print {
    .table-card .card-header {
      padding: 0.5rem !important;
    }
    .table td, .table th {
      padding: 0.5rem !important;
      font-size: 12px !important;
      letter-spacing: normal !important;
      text-transform: none !important;
    }

    /*table {
      page-break-before:unset!important;
      page-break-inside: avoid!important;
      page-break-after:unset!important;
    }*/
    thead {
      page-break-before: unset !important;
      page-break-inside: unset !important;
      page-break-after: unset !important;
    }
    /* tr[role="row"] {
       page-break-before:always!important;
       page-break-inside: avoid!important;
       page-break-after:unset!important;
     }*/
    /*tr[role="row"] td {
      page-break-before:unset!important;
      page-break-inside: avoid!important;
      page-break-after:unset!important;
    }*/

    .card .card-header {
      padding-top: 0 !important;
    }
    .form-control {
      border: 0 !important;
    }
    .vs__dropdown-toggle {
      border: 0 !important;
    }
    .vs__actions {
      display: none !important;
    }
    .media-aside {
      margin-right: 0;
    }
    .b-avatar {
      display: none;
    }
    .col-student {
      width: auto !important;
    }
    .col-festival {
      width: auto !important;
    }
    .col-score {
      width: auto !important;
    }
    .col-local {
      width: auto !important;
    }
    .col-state {
      width: auto !important;
    }
    .col-ensemble {
      width: auto !important;
    }
    .col-part {
      width: auto !important;
    }
  }
}

.v-select::v-deep {
  .vs__selected-options {
    flex-wrap: nowrap;
    max-width: calc(100% - 40px); /* change this to `- 40px` if you're supporting a `clearable` field; I was not */
  }

  .vs__selected {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
  }
}
</style>
